<div class="pagination mt-4">
    <img class="page-button-control" src="/assets/images/icons/left.png"
    (click)="backBatchPage()" [hidden]="!canBackBatchPage()">

    <!-- Show 10 pages per cycle-->
    <a class="page-number"
    [ngClass]="{
        'selected': currentPage == (i+pageOffsetStart)+1
    }"
    *ngFor="let x of [].constructor(paginationData?.maxPage) |
    slice:pageOffsetStart:pageOffsetStart+10; let i=index"
    (click)="selectNewPage(i+pageOffsetStart+1)">
        <div class="num">
            {{i+pageOffsetStart+1}}
        </div>
    </a>
    
    <img class="page-button-control" src="/assets/images/icons/right.png" 
    (click)="nextBatchPage()" [hidden]="!canNextBatchPage()">
</div>