import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionService } from 'src/app/service/transaction/transaction.service';

@Component({
  selector: 'app-print-transcation',
  templateUrl: './print-transcation.component.html',
  styleUrls: ['./print-transcation.component.scss']
})
export class PrintTranscationComponent implements OnInit {
  data: any = {}
  flags={
    selectedID: null
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private transactionSvc: TransactionService,
  ) {
    this.flags.selectedID = this.activatedRoute.snapshot.params.id
  }

  async ngOnInit(): Promise<void> {
    let res: any = await this.transactionSvc.getTransactionDetail(this.flags.selectedID).toPromise()
    this.data = res.Transaction
    console.log(res)
    setTimeout(()=>{
      print()
    },500)
  }

}
