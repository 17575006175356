import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForbiddenStateSerivce {
  forbiddenStateSubject = new BehaviorSubject(null)
  constructor() {
    this.forbiddenStateSubject.next(false)
  }

  declareForbid(){
    this.forbiddenStateSubject.next(true)
  }
  declareNotForbid(){
    this.forbiddenStateSubject.next(false)
  }
}
