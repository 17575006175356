import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(
    private http: HttpClient
  ) { }

  getTransactions(startDate, endDate, page, user?){

    let lnk = environment.API_URL+'transaction/findAll?page='+(page-1)
    +( (startDate && endDate) ? `&startDate=${startDate}&endDate=${endDate}` : '') +
    (user ? `&user=${user}` : '')
    
    console.log(lnk)
    return this.http.get(lnk)
  }

  getTransactionDetail(id){
    let lnk = environment.API_URL+'transaction/find/'+id
    return this.http.get(lnk)
  }

}
