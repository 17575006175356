import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth/auth.service';
import { ForbiddenStateSerivce } from '../service/utils/forbidden-state/forbidden-state.service';

@Injectable({
    providedIn: "root"
})

export class LoginGuardService implements CanActivate {
    constructor(
        public router: Router,
        private authSvc: AuthService,
        private forbidden: ForbiddenStateSerivce,
        private location: Location,
        private toastSvc: ToastrService
      ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
      // if user is logged in
      if(this.authSvc.isAuthenticated()) {
          //get keyword of role in route data (as stated in Routes)
          if(route.data.role){
            //get user data role
            let usrRole = this.authSvc.getUserToken()

            if(usrRole[route.data.role] === undefined){
              //no permission keyword, allow it
              this.forbidden.declareNotForbid()      
              return true
            }
            else if(usrRole[route.data.role] === true){
              this.forbidden.declareNotForbid()
              return true
            }else{
              //if current keyword means false to the current user (not allowed)
              this.forbidden.declareForbid()      
              return true
            }
          }else{
            //no role keyword? means everyone can access this route
            this.forbidden.declareNotForbid()
            return true
          }
      }else{
        let url = this.location.path()
        console.log(url)
        this.toastSvc.info("Mohon login untuk mengakses halaman ini")
        // Please login to access this page
        this.router.navigate(['/auth'],{
            replaceUrl: true,
            state:{
              url_redirects: url
            }
        });
        return false;
      }
    }
  }