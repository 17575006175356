<quill-editor
[modules]="qModules"
[formControl]="form"
[styles]="{  height: minHeight+'pt'}">
    <div quill-editor-toolbar>
        <span class="ql-formats">
            <select class="gbtn ql-header">
                <option value="1">Heading 1</option>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option value="4">Heading 4</option>
                <option value="5">Heading 5</option>
                <option value="6">Heading 6</option>
                <option value="">Normal</option>
            </select>
            <button class="gbtn ql-bold" [title]="'Bold'"></button>
            <button class="gbtn ql-italic" [title]="'Italic'"></button>
            <button class="gbtn ql-underline" [title]="'Underline'"></button>
            <button class="gbtn ql-clean"></button>
        </span>
        <span class="ql-formats">
            <button class="gbtn ql-align" value=""></button>
            <button class="gbtn ql-align" value="center"></button>
            <button class="gbtn ql-align" value="right"></button>
            <button class="gbtn ql-align" value="justify"></button>
        </span>

        <span class="ql-formats">
            <button class="gbtn ql-list" value="ordered" [title]="'List'"></button>
            <button class="gbtn ql-list" value="bullet" [title]="'Bullet List'"></button>
        </span>
    </div>
</quill-editor>