<div class="main">
    <div style="position: absolute; top:0; z-index: 2;">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
        </div>
    </div>
    <div class="head pb-5 pt-5">
        <img class="hamburger" (click)="collapsed = !collapsed; onCollapseChange.emit(collapsed)" src="/assets/images/sidebar/hamburger.png">
        <img src="/assets/images/icons/company_logo.png" width="132">
    </div>
    <ul class="menus w-100" *ngIf="adminSession">
        <!-- [hidden]="!adminSession[item.index]"-->
        <li *ngFor="let item of menus"
            [ngClass]="{
                'active': urlIsOnView(item)
            }"
            (click)="nav(item)">
            <div class="row">
                <div class="col-auto">
                    <img class="icon" [src]="urlIsOnView(item)? item.img_active : item.img"
                    [ngClass]="{
                        'active': urlIsOnView(item)
                    }">
                </div>
                <div class="col px-0">
                    {{item.name }}
                </div>
                <div class="col-auto text-right" *ngIf="item.index == 'chatManagement' ">
                    <span class="badge badge-danger">
                        {{numerOfUnreadChatRoom}}
                    </span>
                </div>
            </div>
        </li>
    </ul>
</div>