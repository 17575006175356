import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Input('title') title 
  @Input('withBackButton') bb?: boolean

  constructor(
    private location: Location,
    private ngZone: NgZone,
    private router: Router,
    private toastSvc: ToastrService,
    private authSvc: AuthService,
  ) { }

  getName(){
    return this.authSvc.getUserToken()?.name
  }

  dialogShown: boolean

  ngOnInit(): void {

  }

  goBack(){
    try{
      this.location.back()
    }catch(e){
      console.warn(e)
    }
  }

  signOut(){
    this.authSvc.deleteUserToken()
    this.router.navigateByUrl('/auth')
    this.toastSvc.success("Berhasil logout!")
  }
}
