import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { state, trigger, style, transition, animate } from '@angular/animations';

import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id'; 
import { LoadIndicatorService } from './service/utils/load-indicator/load-indicator.service';
import { ForbiddenStateSerivce } from './service/utils/forbidden-state/forbidden-state.service';
import { AuthService } from './service/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
registerLocaleData(localeId, 'id'); 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations:[
    trigger('load',[
      state('ready',style({
        'height': 0,
      })),
      state('load',style({
        'height': '*',
      })),
      transition('load => ready', [
        animate('0.2s cubic-bezier(0.35, 0, 0.25, 1)')
      ]),
      transition('ready => load', [
        animate('0.2s cubic-bezier(0.35, 0, 0.25, 1)')
      ]),
    ]),
    trigger('collapseSideBar', [
      state('false', style({
        'width': '220pt',
      })),
      state('true', style({
        'width': '60pt',
        'overflow': 'hidden'
      })),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.35, 0, 0.25, 1)')
      ]),
      transition('true => false', [
        animate('0.3s cubic-bezier(0.35, 0, 0.25, 1)')
      ]),
    ]),

    trigger('expandedMenu', [
      state('false', style({
        'width': 'calc(100% - 220pt)',
      })),
      state('true', style({
        'width': 'calc(100% - 60pt)',
        'box-shadow': '-10px 0px 10px #9B9B9BC0'

      })),
      transition('false => true', [
        animate('0.3s cubic-bezier(0.35, 0, 0.25, 1)')
      ]),
      transition('true => false', [
        animate('0.3s cubic-bezier(0.35, 0, 0.25, 1)')
      ]),
    ]),
  ],
})

export class AppComponent{
  collapsed = false

  onRequestHTTP: Boolean
  requestHTTPStatus: any

  isForbidden: Boolean

  constructor(
    private router: Router,
    private loadSvc: LoadIndicatorService,
    private forbiddenSvc: ForbiddenStateSerivce,
  ){
    
    this.router.events.subscribe(r=>{
      
      if(r instanceof NavigationEnd){        
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    })

    this.loadSvc.loaderStatusSubject.subscribe(res=>{
      setTimeout(()=>{
        this.onRequestHTTP=res
      },0)
    })


    this.loadSvc.loaderStatusDescriptionSubject.subscribe(res=>{
      setTimeout(()=>{
        this.requestHTTPStatus=res || "Memproses, Mohon tunggu..."
      },0)
    })

    this.forbiddenSvc.forbiddenStateSubject.subscribe(res=>{
      setTimeout(()=>{
        this.isForbidden=res
      },0)
    })
    
  }

  fullScreen(){
    return this.router.url.includes('/auth') || this.router.url.includes('/print')
  }

  onCollapseChange(val){
    this.collapsed=val
    console.log(this.collapsed)
  }

}
