import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuardService } from './guards/auth-guard';
import { PrintTranscationComponent } from './pages/print/print-transcation/print-transcation.component';

const routes: Routes = [
  {
    path: 'auth', loadChildren: ()=>import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard', loadChildren: ()=>import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [LoginGuardService],
    data:{
      role: 'dashboard'
    }
  },
  {
    path: 'user', loadChildren: ()=>import('./pages/user/user.module').then(m => m.UserModule),
    data: {
      role: 'userManagement'
    },
    canActivate: [LoginGuardService]
  },
  {
    path: 'content', loadChildren: ()=>import('./pages/content/content.module').then(m => m.ContentModule),
    data: {
      role: 'contentManagement'
    },
    canActivate: [LoginGuardService]
  },
  {
    path: 'chat', loadChildren: ()=>import('./pages/chat/chat.module').then(m => m.ChatModule),
    data: {
      role: 'chatManagement'
    },
    canActivate: [LoginGuardService]
  },
  {
    path: 'announcement', loadChildren: ()=>import('./pages/announcement/announcement.module').then(m => m.AnnouncementModule),
    data: {
      role: 'announcementManagement'
    },
    canActivate: [LoginGuardService]
  },
  {
    path: 'transaction', loadChildren: ()=>import('./pages/transaction/transaction.module').then(m => m.TransactionModule),
    data: {
      role: 'transactionManagement'
    },
    canActivate: [LoginGuardService]
  },
  {
    path: 'counselling', loadChildren: ()=>import('./pages/counselling/counselling.module').then(m => m.CounsellingModule),
    canActivate: [LoginGuardService],
    data: {
      role: 'counselingManagement'
    },
  },
  {
    path: 'langganan', loadChildren: ()=>import('./pages/langganan/langganan.module').then(m => m.LanggananModule),
    canActivate: [LoginGuardService],
    data: {
      role: 'membershipManagement'
    },
  },
  {
    path: 'about', loadChildren: ()=>import('./pages/about/about.module').then(m => m.AboutModule),
    canActivate: [LoginGuardService],
    data: {
      role: 'aboutManagement'
    },
  },
  {
    path: 'article', loadChildren: ()=>import('./pages/article/article.module').then(m => m.ArticleModule),
    canActivate: [LoginGuardService],
    data: {
      role: 'articleManagement'
    },
  },
  {
    path: 'assesment', loadChildren: ()=>import('./pages/assesment-issue/assesment-issue.module').then(m => m.AssesmentIssueModule),
    canActivate: [LoginGuardService],
    data: {
      role: 'issueManagement'
    },
  },
  {
    path: 'admin', loadChildren: ()=>import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [LoginGuardService],
    data:{
      role: 'adminManagement'
    }
  },
  {
    path: 'print/transaction/:id', 
    component:  PrintTranscationComponent,
    canActivate: [LoginGuardService],
    data: {
      role: 'transactionManagement'
    },
  },
  {
    path: '**', redirectTo: 'auth', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
