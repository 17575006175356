import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../utils/secure-storage/secure-storage.service';
import { ChatService } from '../chat/chat.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private secureStorageSvc: StorageService,
    private chatSvc: ChatService
  ) { }

  login(body){
    return this.http.post<any>(environment.API_URL+'admin/login',body, {
      headers: new HttpHeaders({
        'Content-Type':'application/json'
      })
    })
  }

  forgotPassword(body) {
    console.log('done kirim email')
    return this.http.post(environment.API_URL+'admin/password/forgot',body, {
      headers: new HttpHeaders({
        'Content-Type':'application/json'
      })
    })
  }

  resetPassword(body){
    let lnk = environment.API_URL+'admin/password/reset'
    return this.http.put(lnk, body)
  }

  setUserToken(loginInfo, email){
    this.secureStorageSvc.setValue('parenting-bo-admin-token',{...loginInfo, email: email})
  }

  getUserToken(){
    return this.secureStorageSvc.getValue('parenting-bo-admin-token')
  }

  async deleteUserToken(){
    await this.chatSvc.closeConnection()
    this.secureStorageSvc.destoryValue('parenting-bo-admin-token')
  }

  isAuthenticated(): boolean{
    try{
      return this.getUserToken().Token != null
    }catch(e){
      return false
    }
  }
}
