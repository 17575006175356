import { Component, OnInit, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-toggle-on-off',
  templateUrl: './toogle-on-off.component.html',
  styleUrls: ['./toogle-on-off.component.scss'],
  animations: [
    trigger('toggle', [
      state('false', style({
        'left': '-4pt',
        'background-color': "#637280",
      })),
      state('true', style({
        'left': '24pt',
        'background-color': "#00C23A",
      })),
      transition('false => true', [
        animate('0.1s')
      ]),
      transition('true => false', [
        animate('0.1s')
      ]),
    ]),
  ]
})
export class ToogleOnOffComponent implements OnInit {
  @Input('initialValue') v
  @Output('onToggle') onChanges=new EventEmitter()
  toggleState

  ngOnChanges(){
    this.ngOnInit()
  }

  ngOnInit(): void {
    this.toggleState=this.v
  }

  toggle(){
    this.toggleState = !this.toggleState
    console.log(this.toggleState)
    this.onChanges.emit(this.toggleState ? true : false)
  }
}
