import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-table-filter-search',
  templateUrl: './table-filter-search.component.html',
  styleUrls: ['./table-filter-search.component.scss'],
  animations:[
    trigger('search', [
      state('open', style({
          'height': '*',
          'opacity': '1.0',
      })),
      state('closed', style({
          'height': '0',
          'opacity': '0.0',
      })),
      transition('open => closed', [
        animate('0.3s cubic-bezier(0,-0.07,0,.94)')
      ]),
      transition('closed => open', [
        animate('0.3s cubic-bezier(0,-0.07,0,.94)')
      ]),
    ]),

    trigger('filterPopup', [
      state('open', style({
          'height': '*',
          'opacity': '1.0',
          'z-index': '2'
      })),
      state('closed', style({
          'height': '0',
          'opacity': '0.0',
          'z-index':'-3'
      })),
      transition('open => closed', [
        animate('0.15s')
      ]),
      transition('closed => open', [
        animate('0.15s')
      ]),
    ]),
  ],
})

export class TableFilterSearchComponent implements OnInit {
  @Input('tabs') tabs: {name: any, value: any}[]
  @Input('preSelectedValue') value: string

  @Input('hideFilterButton') hideFilter?: boolean

  @Input('CTAName') ctaName: string
  @Output('onClickCTA') onClickCTA = new EventEmitter()
  
  @Output('onClickTab') onClickTab = new EventEmitter()
  @Output('onClickFilter') onClickFilter = new EventEmitter()
  @Output('onSearch') oS = new EventEmitter()

  @Input('onCloseFilter') onCloseFilter?: EventEmitter<any>

  @Input() msSearchDelay?: any

  isOpenSearchForm: any
  isOpenFilter: any

  searchTxt: any
  
  private timeOut: any

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.searchTxt = this.activatedRoute.snapshot.queryParams.search || ''

    if(this.onCloseFilter){
      this.onCloseFilter.subscribe((res)=>{
        this.isOpenFilter=false
        console.log(res)
      })
    }
  }

  selectTab(item){
    if(this.value != item.value){
      this.value = item.value
      this.onClickTab.emit(item.value)
      this.isOpenFilter=false
      this.isOpenSearchForm=false
    }
  }

  cta(){
    this.onClickCTA.emit()
  }

  openSearch(){
    if(!this.isOpenSearchForm){
      this.isOpenSearchForm=true
      this.isOpenFilter=false
    }else{
      this.isOpenSearchForm=false
    }
  }

  onSearch(event){
    if(this.timeOut) clearTimeout(this.timeOut)
    if(this.msSearchDelay){
      this.timeOut = setTimeout(()=>{
        this.oS.emit(event.target.value)
      }, this.msSearchDelay)
    }else{
      this.oS.emit(event.target.value)
    }
  }
}
