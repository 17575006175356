import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input('id') id: any
  @Input('preventDismiss') preventDismiss?: boolean
  @Input('largerDialog') largerDialog?: boolean


  constructor() { }

  ngOnInit(): void {
    
  }

}
