<div class="header-wrap row mb-3">
    <div class="title col-4 p-0 d-flex justify-content-start 
    align-items-center">
        <div class="mr-5" style="cursor: pointer" [hidden]="!bb" (click)="goBack()">
            <img src="/assets/images/icons/chevron_left.png">
        </div>{{title}}
    </div>
    <div class="col-5">
        
    </div>
    <div class="user col-3 pr-0 d-flex justify-content-end align-items-center cursor-pointer"
    (click)="dialogShown = !dialogShown">
        <div class="user-name pr-4">
            {{getName()}}
        </div>
        <div class="user-avatar">
            <img src='/assets/images/icons/person.png'>
        </div>
    </div>
</div>


<div class="menu-container" *ngIf="dialogShown">
    <div class="menu-item" (click)="signOut()">
        Sign Out
    </div>
</div>