import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';
import { ChatService } from 'src/app/service/chat/chat.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Output('onCollapseChange') onCollapseChange = new EventEmitter()

  adminSession: any

  collapsed = false
  menus = [
    {
      index: 'dashboard',
      name: 'Dashboard',
      url: '/dashboard',
      img: '/assets/images/sidebar/dashboard.png',
      img_active: '/assets/images/sidebar/dashboard_active.png'
    },
    {
      index: 'userManagement',
      name: 'User',
      url: '/user',
      img: '/assets/images/sidebar/user.png',
      img_active: '/assets/images/sidebar/user_active.png'
    },
    {
      index: 'contentManagement',
      name: 'Content',
      url: '/content',
      img: '/assets/images/sidebar/content.png',
      img_active: '/assets/images/sidebar/content_active.png'
    },
    {
      index: 'chatManagement',
      name: 'Chat',
      url: '/chat',
      img: '/assets/images/sidebar/chat.png',
      img_active: '/assets/images/sidebar/chat_active.png'
    },
    {
      index: 'announcementManagement',
      name: 'Announcement',
      url: '/announcement',
      img: '/assets/images/sidebar/announcement.png',
      img_active: '/assets/images/sidebar/announcement_active.png',
    },
    {
      index: 'transactionManagement',
      name: 'Transaction',
      url: '/transaction',
      img: '/assets/images/sidebar/transaction.png',
      img_active: '/assets/images/sidebar/transaction_active.png'
    },
    {
      index: 'counselingManagement',
      name: 'Counseling',
      url: '/counselling',
      img: '/assets/images/sidebar/counselling.png',
      img_active: '/assets/images/sidebar/counselling_active.png'
    },
    {
      index: 'membershipManagement',
      name: 'Membership',
      url: '/langganan',
      img: '/assets/images/sidebar/archive.png',
      img_active: '/assets/images/sidebar/archive_active.png'
    },
    {
      index: 'articleManagement',
      name: 'Article',
      url: '/article',
      img: '/assets/images/sidebar/article.png',
      img_active: '/assets/images/sidebar/article_active.png'
    },
    {
      index: 'aboutManagement',
      name: 'About Parenting',
      url: '/about',
      img: '/assets/images/sidebar/about-parenting.png',
      img_active: '/assets/images/sidebar/about-parenting_active.png'
    },
    {
      index: 'assesmentIssueManagement',
      name: 'Assesment Issue',
      url: '/assesment',
      img: '/assets/images/sidebar/class.png',
      img_active: '/assets/images/sidebar/class_active.png'
    },
    {
      index: 'adminManagement',
      name: 'Admin',
      url: '/admin',
      img: '/assets/images/sidebar/admin.png',
      img_active: '/assets/images/sidebar/admin_active.png'
    }
  ]
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authSvc: AuthService,
    private chatSvc: ChatService
  ) {
    this.adminSession = this.authSvc.getUserToken()
  }

  numerOfUnreadChatRoom: any
  numerOfUnreadChatContent = 0
  numerOfUnreadChatGeneral = 0

  ngOnInit(): void {
    this.numerOfUnreadChatRoom = 0
    this.chatSvc.getChatRoomSuscription().subscribe(res => {
      this.numerOfUnreadChatContent = 0
      res.forEach(i => {
        let data: any = i.payload.doc.data()
        if (data.isUnread) this.numerOfUnreadChatContent += 1
      })
      this.numerOfUnreadChatRoom = this.numerOfUnreadChatContent + this.numerOfUnreadChatGeneral
    })
    this.chatSvc.getChatGeneralSuscription().subscribe(res => {
      this.numerOfUnreadChatGeneral = 0
      res.forEach(i => {
        let data: any = i.payload.doc.data()
        if (data.isUnread) this.numerOfUnreadChatGeneral += 1
      })
      this.numerOfUnreadChatRoom = this.numerOfUnreadChatContent + this.numerOfUnreadChatGeneral
    })
  }

  urlIsOnView(item) {
    return this.router.url.includes(item.url)
  }

  nav(item) {
    this.router.navigateByUrl(item.url)
  }
}
