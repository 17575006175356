// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://parenting-api.sprout.codes/API/v1/backoffice/',
  // API_URL: 'http://localhost:3004/API/v1/backoffice/',
  firebaseConfig:{
    apiKey: "AIzaSyCbF4hYOHMo_78AmLnPLRDke_HZWgJsdzc",
    authDomain: "parenting-app-abbalove.firebaseapp.com",
    databaseURL: "https://parenting-app-abbalove.firebaseio.com",
    projectId: "parenting-app-abbalove",
    storageBucket: "parenting-app-abbalove.appspot.com",
    messagingSenderId: "14963400947",
    appId: "1:14963400947:web:f9559e163186f8c0b11786",
    measurementId: "G-8ES51T0SFG"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
