<div class="row bordered rounded-corner m-5">
    <div class="col-12 d-flex justify-content-center">
        <img src="assets/images/icons/company_logo.png" height="64pt">
    </div>
    <div class="col p-3">
        <h6>Infomasi Transaksi</h6>
        <label class="gray">NO. Pesanan</label>
        <h6 class="dark-gray">#{{data.id}}</h6>

        <label class="gray">NO. Transaksi</label>
        <h6 class="dark-gray">{{data.noTransaction}}</h6>

        <label class="gray">Tanggal & Waktu</label>
        <h6 class="dark-gray">{{data.createdAt}}</h6>

        <label class="gray">User</label>
        <h6 class="dark-gray">{{data.user}}</h6>

        <label class="gray">Tipe Pembayaran</label>
        <h6 class="dark-gray">{{data.tipeLangganan}}</h6>

        <label class="gray">Nominal</label>
        <h6 class="dark-gray">{{data.revenue}}</h6>

        <label class="gray">Metode Pembayaran</label>
        <h6 class="dark-gray">{{data.metodePembayaran}}</h6>
    </div>
</div>