import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  
  private s={
    email: "donotdelete-admin@parenting.com",
    password: "XZoypi20g739HGFkjsh&SJK298nJKHhjkghs"
  }

  constructor(
    private http: HttpClient,
    private fireAuth: AngularFireAuth,
    private db: AngularFirestore,
  ) { }

  async openConnection(){
    return await this.fireAuth.signInWithEmailAndPassword(this.s.email, this.s.password)
  }

  getChatRoomSuscription(){
    return this.db.collection('chat-rooms').snapshotChanges()
  }

  getChatGeneralSuscription(){
    return this.db.collection('chat-general').snapshotChanges()
  }

  getSingleChatRoomSuscription(roomID){
    return this.db.collection('chat-rooms').doc(roomID).collection('messages').valueChanges()
  }

  getSingleChatGeneralSuscription(roomID){
    return this.db.collection('chat-general').doc(roomID).collection('messages').valueChanges()
  }

  async closeConnection(){
    return await this.fireAuth.signOut()
  }

  async sendMessage(body){
    // let res = await this.http.post(environment.API_URL+'chat/create',body).toPromise()
    // await this.db.collection('chat-rooms').doc(body.documentId).update({lastUpdate : new Date(new Date()), isUnread: false})
    return this.http.post(environment.API_URL+'chat/create',body).toPromise()
  }

  async sendMessageGeneral(body){
    // let res = await this.http.post(environment.API_URL+'chat/create-general',body).toPromise()
    // await this.db.collection('chat-general').doc(body.documentId).update({lastUpdate : new Date(new Date()), isUnread: false})
    return this.http.post(environment.API_URL+'chat/create-general',body).toPromise()
  }

  deleteConversation(selectedChatRoom){
    if (selectedChatRoom.general) {
      var tmp = this.db.collection('chat-general').doc(selectedChatRoom.roomID).collection('messages').get()
      tmp.forEach(el => {
        el.docs.forEach(e => {
          return this.db.collection('chat-general').doc(selectedChatRoom.roomID).collection('messages').doc(e.id).delete()
        })
      })
    }
    else {
      var tmp = this.db.collection('chat-rooms').doc(selectedChatRoom.roomID).collection('messages').get()
      tmp.forEach(el => {
        el.docs.forEach(e => {
          return this.db.collection('chat-rooms').doc(selectedChatRoom.roomID).collection('messages').doc(e.id).delete()
        })
      })
    }
  }

  closeConversation(selectedChatRoom){
    if (selectedChatRoom.general) {
			return this.db.collection('chat-general').doc(selectedChatRoom.roomID).update({isClosed: true})
    } else {
			return this.db.collection('chat-rooms').doc(selectedChatRoom.roomID).update({isClosed: true})
    }
  }

  selectConversation(selectedChatRoom){
    if (selectedChatRoom.general) {
			return this.db.collection('chat-general').doc(selectedChatRoom.roomID).update({isUnread: false})
    } else {
			return this.db.collection('chat-rooms').doc(selectedChatRoom.roomID).update({isUnread: false})
    }
  }
}
