import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadIndicatorService {
  loaderStatusSubject = new BehaviorSubject(null)
  loaderStatusDescriptionSubject = new BehaviorSubject(null)

  constructor() {
    this.loaderStatusSubject.next(false)
  }

  assignMessage(str){
    this.loaderStatusDescriptionSubject.next(str)
  }

  show(){
    this.loaderStatusSubject.next(true)
  }
  
  hide(){
    this.loaderStatusSubject.next(false)
    this.loaderStatusDescriptionSubject.next(null)

  }
}
