


import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../service/auth/auth.service';

@Injectable()
export class APITokenInterceptor implements HttpInterceptor {
  constructor(
    private toastSvc: ToastrService,
    private authSvc: AuthService
  ) {
  }

  intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // action interceptor
    
    let sts = this.authSvc.getUserToken()
    if(sts){
      request = request.clone({
        setHeaders: {
          token: this.authSvc.getUserToken().Token
        }
      });
    }
    return next.handle(request);
   }
}