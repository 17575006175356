import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-errors',
  templateUrl: './invalid-formcontrol-text.component.html',
  styleUrls: ['./invalid-formcontrol-text.component.scss']
})
export class InvalidFormcontrolTextComponent implements OnInit {
  @Input() fControl: FormControl

  formMsg={
    'required': "Kolom ini diperlukan",
    'email': "Format email salah",
    'pwd': "Password harus memiliki minimal 6 karakter, 1 Huruf Besar, dan 1 digit angka"
  }

  constructor() { }

  ngOnInit(): void {
  }

}
