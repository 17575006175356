import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit {
  @Input() minHeight
  @Input() form?: FormControl

  qModules={
    toolbar: [
      [{ 'size':  ['12px', '16px', '18px','20px'] }]
    ]
  }     
  
  
  constructor() {
    if(!this.form) this.form = new FormControl()
  }

  ngOnInit(): void { }

}
