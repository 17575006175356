<div class="row pb-1">
    <div class="col-auto pl-0" *ngIf="hideFilter">

    </div>
    <div class="col-auto pl-0 d-flex justify-content-start align-items-center filter-btn"
    *ngIf="!hideFilter"
    (click)="isOpenFilter = !isOpenFilter">
        <img style="width:24px; height: 24px;" class="mr-2" src="/assets/images/icons/filter.png">Filter
    </div>
    <div class="col tabs d-flex justify-content-around">
        <div class="tab mx-3"
        [ngClass]="{
            active: item.value == value
        }" *ngFor="let item of tabs"
        (click)="selectTab(item)">{{item.name}}
            <div class="underside" [hidden]="item.value != value"></div>
        </div>
    </div>
    <div class="col-3 pr-0 d-flex justify-content-end">
        <button class="cta-button" (click)="cta()"*ngIf='ctaName'>{{ctaName}}</button>
        <img class="icon ml-2" src="/assets/images/icons/search_button.png"
        (click)="openSearch()"
        style="cursor: pointer" >
    </div>
</div>

<div class="row p-0 mt-2" [@search]="isOpenSearchForm? 'open': 'closed'">
    <div class="col-12 p-0">
        <input class="form-control" placeholder="Type something..."
        [(ngModel)]="searchTxt" (keyup)="onSearch($event)">
    </div>
</div>

<div class="row pb-1">
    <div class="col-12 p-0">
        <div class="bubble" [@filterPopup]="isOpenFilter? 'open': 'closed'">
            <ng-content></ng-content>
        </div>
    </div>
</div>