import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination-navigator',
  templateUrl: './pagination-navigator.component.html',
  styleUrls: ['./pagination-navigator.component.scss']
})
export class PaginationNavigatorComponent implements OnInit, OnChanges {
  private subscription: Subscription

  @Input('paginationData') paginationData: {
    currentPage: any,
    limit:any,
    maxPage: any,
    count: any
  }
  
  @Output('onPageSelect') onPageSelect = new EventEmitter()

  pageOffsetStart 
  currentPage
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnDestroy(){
    if(this.subscription) this.subscription.unsubscribe()
  }

  ngOnChanges(){
    this.currentPage = this.paginationData?.currentPage || 1
    let n = Math.floor((this.currentPage-1)/10.00)
    this.pageOffsetStart = n*10
  }

  ngOnInit(): void {
    let pg = this.activatedRoute.snapshot.queryParams.page
    this.currentPage = pg || 1
    //this.subscription=this.router.events.subscribe((_)=>{
     // this.ngOnChanges()
    //})
  }


  backBatchPage() {
    if(this.canBackBatchPage) this.pageOffsetStart-=10
  }

  canBackBatchPage(){
    return this.pageOffsetStart-10 >= 0
  }

  nextBatchPage() {
    if(this.canNextBatchPage) this.pageOffsetStart+=10
  }

  canNextBatchPage(){
    return (this.pageOffsetStart+10+1) <= this.paginationData?.maxPage
  }

  selectNewPage(i){
    if(i == this.currentPage) return

    this.currentPage=i
    this.onPageSelect.emit(i)
  }
}
