<div class="animated-gradient" [@load]="onRequestHTTP ? 'load' : 'ready'">
  <div style="color: white; font-size: 8pt;" class="p-2">
    {{requestHTTPStatus}}
  </div>
</div>

<div class="row" *ngIf="fullScreen(); else splitScreen">
  <div class="col full-page">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #splitScreen>
  <div class="row px-0">
    <div class="col-auto left-side" [@collapseSideBar]="collapsed ? 'true': 'false'">
      <app-sidebar (onCollapseChange)="onCollapseChange($event)"></app-sidebar>
    </div>

    <div class="col right-side " *ngIf="isForbidden"
      [@expandedMenu]="collapsed ? 'true': 'false'">
      
      <app-header></app-header>
      <div class="forbidden
      d-flex align-items-center flex-column">
        <img src="assets/images/icons/forbidden.png" class="mt-auto">
        <p class="mb-auto mt-3">Anda tidak dapat mengakses halaman ini. Mohon menghubungi sesama admin anda</p>
      </div>
    </div>

    <div class="col right-side"
    [@expandedMenu]="collapsed ? 'true': 'false'"
    *ngIf="!isForbidden">
      <router-outlet></router-outlet>
    </div>

  </div>
</ng-template>