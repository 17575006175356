import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[progressiveNumber]'
})
export class ProgressiveNumberDirective implements OnChanges{
  public styles: CSSStyleDeclaration;
  private int

  @Input('input') targetValue

  constructor(public el: ElementRef) {
  }

  ngOnChanges(){
    this.ngOnInit()
  }

  ngOnInit() {

    this.el.nativeElement.innerHTML=0
    this.int = setInterval(()=>{
      this.el.nativeElement.innerHTML= parseInt(this.el.nativeElement.innerHTML)+100

      if(parseInt(this.el.nativeElement.innerHTML) >= this.targetValue){
        this.el.nativeElement.innerHTML=this.targetValue
        clearTimeout(this.int)
         
      }
    },0.001)
  }

}
