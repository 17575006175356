<!-- Button trigger modal -->
<button [id]="'openD'+id" hidden data-toggle="modal"
[attr.data-target]="'#D'+id">
</button>

<!-- Modal -->
<div class="modal fade" [id]="'D'+id" tabindex="-1" role="dialog" aria-hidden="true"
[attr.data-backdrop]="preventDismiss ?  'static' : null"
[attr.data-keyboard]="preventDismiss ? 'false': null">

<button [id]="'closeD'+id" hidden data-dismiss="modal">
</button>
    
    <div class="modal-dialog modal-dialog-centered" [ngClass]="{'modal-dialog-large': largerDialog}" role="document">
        <div class="modal-content pt-3 pb-3">
            <div class="modal-dismiss-btn" data-dismiss="modal">✖</div>
            <div class="modal-body text-center">
                <ng-content></ng-content>
            </div>
        </div>
        <div [hidden]="preventDismiss" class="modal-caption">Click anywhere to dismiss</div>
    </div>
</div>
