import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize} from 'rxjs/operators';
import { LoadIndicatorService } from '../service/utils/load-indicator/load-indicator.service';

@Injectable({
    providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    private loaderIndicatorSvc: LoadIndicatorService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderIndicatorSvc.show()
    return next.handle(req).pipe(
      finalize(() => {
          this.loaderIndicatorSvc.hide()
      })
    );

  }
}