import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { ToogleOnOffComponent } from './toogle-on-off/toogle-on-off.component';
import { TableFilterSearchComponent } from './table-filter-search/table-filter-search.component';
import { PaginationNavigatorComponent } from './pagination-navigator/pagination-navigator.component';
import { ReactiveFormsModule }   from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { HttpClientModule } from '@angular/common/http';
import { ProgressiveNumberDirective } from '../directives/progressive-number.directive';
import { InvalidFormcontrolTextComponent } from './invalid-formcontrol-text/invalid-formcontrol-text.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { QuillModule } from 'ngx-quill';

const core=[
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
]

const frequentlyUsedComponent=[
  HeaderComponent,
  ToogleOnOffComponent,
  TableFilterSearchComponent,
  PaginationNavigatorComponent,
  ModalComponent,
  InvalidFormcontrolTextComponent,
  RichTextEditorComponent
]

const directives=[
  ProgressiveNumberDirective
]

@NgModule({
  declarations: [
    ...frequentlyUsedComponent,
    ...directives],
  imports: [
    QuillModule.forRoot({
      placeholder: 'Ketik Disini'
    }),
    core
  ],
  exports:[
    ...frequentlyUsedComponent,
    ...core,
    ...directives,
  ],
  providers:[
    { provide: LOCALE_ID, useValue: "id-ID" }, 
  ]
})
export class SharedModule { }
